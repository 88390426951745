:root {
  /* Define color variables */
  --wvu-blue:  rgb(11, 36, 62);
  --wvu-gold: #eeb111 ;
  --wvu-gray: #2c2a29;
  --wvu-dark-gray: #232221;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

img {
  max-width: 100%; 
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family:Arial, Helvetica, sans-serif;
  text-align: center;
  background-color:rgb(11, 36, 62);
  color: rgb(0, 0, 0);

}
.container {
  max-width: 800px; 
  margin: auto;
  margin-top: 100px; 
  padding: 20px;
  background-color: #F7F7F7; 
  border-radius: 10px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.grid-container{ 
	display: grid;
	margin: auto; 
	grid-template-columns: auto auto;
	max-width: 800px;
	background-color: #F7F7F7;
	padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
}

.grid-item{
	padding: 20px;
}
#intro{
	font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	font-size:large;
	color: rgb(11, 36, 62);
}
.logo{
	vertical-align:middle;
	max-height: 450px;
	max-width: 300px;
	border-radius: 5px;
	
}
#pwReqs{
  color: rgb(11, 36, 62);
  font-size: smaller;
}

        #uploadForm {
            margin-bottom: 20px;
          }

          
          #imageContainer img {
            max-width: 100%; /* Make sure the image doesn't overflow the container */
          }

		#login-link{
			padding: 10px 50px; 
            margin-right: 10px; 
            font-size:medium;
            margin-top: 20px; 
            background-color: #007bff; 
            color: #fff; 
            border: none; 
            border-radius: 5px; 
            cursor: pointer; 
		}
		#login-link:hover{
            background-color: #0056b3;
          }
          button {
            padding: 10px 20px; 
            margin-right: 10px; 
            margin-top: 20px; 
            background-color: #007bff; 
            color: #fff; 
            border: none; 
            border-radius: 5px; 
            cursor: pointer; 
          }

          button:hover{
            background-color: #0056b3;
          }
        
          authButton {
            padding: 10px 20px;
            margin-right: 10px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          
          authButton:hover{
            background-color: #0056b3;
          }
        
         input[type="text"], input[type="password"], input[type="email"], input[type="passwordConfirm"] {
            width: 100%;
            padding: 8px;
            margin: 8px 0;
            box-sizing: border-box;
        }

     

Title{
    color: white;
}

h1 {
    color: white; 
  }
  /*font for welcome message*/
#welcomMsg{
	color :rgb(11, 36, 62);
	font-size:x-large; 
	font-weight: bold;
  }

  /* Added margin to properly space the 2FA toggle from the Continue & Back buttons */
#toggle-2fa {
  margin-bottom: 20px;
}

.friendsSidebar{
  width: 200px;
  background-color: #002855;
  float: left;
  position: fixed;
  overflow: auto;
  display: block;
  color: rgb(255, 255, 255);
  padding: 16px;
  text-decoration: none;
  border-radius: 20px;
  
}
#reportProbHeader{
  color: var(--wvu-dark-gray);
}
.ProfileContainer{
  max-width: 800px; 
            margin: auto; 
            padding: 20px; 
            background-color: #F7F7F7; 
            border-radius: 10px; 
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/*profile friends list*/
.friends-list-container {
  max-height: 300px; 
  overflow-y: auto;
  padding: 10px; 
  
}

.friends-scroll-container {
  width: 100%; /* Ensure full width */
}

.friends-scroll-list {
  display: flex;
  flex-direction: column;
}

.friend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.friend-profile-image {
  width: 50px;
  height: 50px; 
  border-radius: 50%;
  margin-right: 10px; 
  object-fit: cover;

}



/*Profile page friends modal*/

.searchForm-email {
  display:flex;


  .emailSearch{
margin-top: 25px;
  }


  .friendSearch  .userImg-search-friend{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;

  }
  .friendSearch .friend-search-result {
    display: flex;
    justify-content: space-between; 
    align-items: center; 

  }

}
/*profile page picture modal*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 40%; 
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; 
  max-width: 450px;
  height: 200px; 
  padding: 50px;
}


.modal-overlay-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-2 {
  background-color: white;
  border-radius: 8px;

  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%; 
  max-width: 450px;
  height: 200px;
  padding: 50px;
}

.upload-button{

  background: var(--wvu-blue);
  border: none;
  cursor: pointer;
}
.upload-button:hover{

  background: var(--wvu-gold);
  border: none;
  cursor: pointer;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--wvu-blue);
  border: none;
  cursor: pointer;
}

.close-button:hover {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--wvu-gold);
  border: none;
  cursor: pointer;
}


.photo-upload-container {
 display:flex;
 flex-direction: column;
 align-items: center;

 gap: 40px;
}


#message{
	width:100%;
	height: 200px;
	padding: 20px,20px;
	resize: none;
}
#cancel{
	background-color: red;
}
  #cancel:hover{
background-color: rgb(183, 4, 4);
  }


  .confirm-pw .eye-icon{
    position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
  }

  .confirm-pw .eye-icon{
    font-size: 18px; /* Change the size as required */
  }

  .confirm-pw{
    position: relative; 
  }

  .confirm-pw input{
    padding-right: 30px;
  }

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 30px;
}


.password-input .eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
}

/* Adjust the icon size if needed */
.password-input .eye-icon svg {
  font-size: 18px; /* Change the size as required */
}


/*Profile page buttons*/

.container .profile-container {

  display: flex;
  

  .headers-section{
    flex: 2;
    display: flex;

   
    .main-profile-img{
      border-radius: 50%; 
      width: 250px;
      height: 250px; 
      border: 2px solid var(--wvu-dark-gray); 
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
      object-fit: cover;

      
    }
  }

  .buttons-profile{
    flex:1;

  
    
  .events-button ,  .upload-Profile-Button , .contact-button, .Add-Friends-Profile-Button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--wvu-blue);
    color: white;
    flex-direction: column; 
    align-items: center; 
    width: 180px; 
    height: 40px;
    margin-bottom: 5px; 

  }





  .events-button:hover ,  .upload-Profile-Button:hover , .contact-button:hover, .Add-Friends-Profile-Button:hover{
    background-color: var(--wvu-gold);
    color: white;
  }
  }


}


/*profile page modal*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 40%; 
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; 
  max-width: 450px;
  height: 200px; 
  padding: 50px;
}


.modal-overlay-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-2 {
  background-color: white;
  border-radius: 8px;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%; 
  max-width: 450px;
  height: 200px;
  padding: 50px;
}

.upload-button{

  background: var(--wvu-blue);
  border: none;
  cursor: pointer;
}
.upload-button:hover{

  background: var(--wvu-gold);
  border: none;
  cursor: pointer;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--wvu-blue);
  border: none;
  cursor: pointer;
}

.close-button:hover {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--wvu-gold);
  border: none;
  cursor: pointer;
}


.photo-upload-container {
 display:flex;
 flex-direction: column;
 align-items: center;

 gap: 40px;
}



/* Style for Navigation Bar */
.masterNavbar {
  background-color: #2c2a29;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0px 15px;
}

.masterNavbar ul {
  display: flex;
  list-style: none;
  justify-content: left;
  padding: 0px;
}

.LeftItems,
.RightItems {
display: flex;
align-items: center;
}


.LeftItems {
margin-right: auto;
color: white;
text-decoration: none;
font-size: 30px;
/* Pushes the right items to the left of the container */
}

.LeftItems > * {
  margin-right: 25px; /* Optional: Add additional margin between items if needed */
}

.RightItems {
  padding-right: 45px;
}

.masterNavbar a{
color: white;
text-decoration: none;  
margin: 0 10px;
}

.masterNavbar a:hover {
  cursor: pointer;
  color: var(--wvu-gold); /* Adjust background color on hover */
 
}

.masterNavbar  .userProfilePhoto{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
 
}



/* Login Page Container*/

.grid-container {
  margin-top: 20px; 
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}

/*sign up page Container*/
#signup-container {
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
}


/* Styles for the Calendar component */
.Calendar {
  margin:auto; 
  margin-top:50px;
  width: 80%;
  background-color: white; 
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
}

.Calendar .calendar-interactions{
  flex: 2;
}
.Calendar .calendar-days-container{
  flex: 6;
  height: 500px; 
  padding-right: 15px;
}

.Calendar .labels {
 padding: 10px;
}

.Calendar #Month-Year {
 padding: 15px;
}

.Calendar .previousMonth{
  background-color:var(--wvu-blue);
}

.Calendar .nextMonth{
 background-color:var(--wvu-blue);
}  

.Calendar .events{
  background-color: var(--wvu-blue);
}
.Calendar .previousMonth:hover{
 background-color:var(--wvu-gold);
}

.Calendar .nextMonth:hover{
 background-color:var(--wvu-gold);
}

.Calendar td {
  text-align: left; 
  border: 1px solid #ccc; 
  padding: 8px; 
  position: relative; 
  height: 50px;
}

.Calendar td span {
  position: absolute; 
  top: 0; 
  left: 0; 
}







/*Messaging Page Formatting*/
.MessagingPage{


  background-color: rgb(11, 36, 62);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container{
    background-color: #2c2a29;
    padding: 0;
    border: 1px solid white;
    border-radius: 10px;
    max-width: 1200px; /* Set a wider max-width value */
    width: 100%; /* Ensures the container takes full width available */
    height: 85%;
    color: white;
    display: flex;
    overflow: hidden;
    
    

    .sidebar{
      flex: 1;
      text-align: left;
      background-color:#2c2a29;
   


      .search{
        border-bottom: ipx solid gray;

      }

      .search .searchForm .messageSearch{
        background-color: transparent;
        color: white;
        border: none;
        outline:none;

      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        color:white;
     

        &:hover{
          background-color: var(--wvu-dark-gray);
          color: var(--wvu-gold);
        }
      }
      .userChat .userImg{
       
          height: 50px;
          width: 50px;
          border-radius: 50%;
          object-fit: cover;
        
      }

      .userChat .userChatInfo .username{
        
          font-size: 18px;
          font-weight: 500;
        
      
      }
      .userChat .userChatInfo .text-preview{
        font-size: 12px;
        color:rgb(192, 192, 192);
        font-weight: lighter;
       
      }
      
      .navbar{
        display: flex;
        align-items: center;
        background-color: #232221;
        height: 50px;
        padding:10px;
        justify-content: space-between;

        .messengerlogo{
          font-weight: bold;
        }

        .addFriend{
          font-size: 30px;
          text-decoration: none;
          color:white;

         
        }
        
        .addFriend:hover{
          cursor: pointer;
          color:var(--wvu-gold)
         
        }

        .userinfo{

          display:flex;
          align-items: center;
          gap: 10px;
          height: 50px;
          
        }

        .userinfo .userImg{
          height: 50px;
          width: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
      }


     
    
    }
 
    .chatWindow{

      


      flex: 2;
      color: black;
      

      
      .chatInfo{
        color:var(--wvu-dark-gray);
        height: 70px;
        padding: 10px;
        background-color: rgb(205, 205, 205);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        .userImg{
          height: 50px;
          width: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
        

      }
      .messages{
        padding: 10px;
        background-color: rgb(255, 255, 255);
        height: calc(100% - 180px);
        overflow-y: scroll;
        

        .message{
          display: flex;
          gap: 20px;
          margin-bottom: 20px;;

          
          .messageInfo{
            color:  rgb(181, 181, 181);
            .userImg{
              height: 50px;
              width: 50px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .messageContent{

            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .messageContentParagraph{
              background-color: rgb(0, 79, 170);
              padding: 10px 20px;
              border-radius: 0px 10px 10px 10px;
              color: white;
              box-shadow: 0px -5px 5px -2px rgba(122, 122, 122, 0.75);
            }
            .user-message-img{
             width: 50%;
             max-width: max-content;

            }
          }

          &.owner{
            flex-direction: row-reverse;
            .messageContent{
              align-items: flex-end;
               .messageContentParagraph{
                  background-color:white;
                  padding: 10px 20px;
                  border-radius: 10px 10px 0px 10px;
                  border: 1px solid #848484;
                  color: rgb(0, 0, 0);
                  box-shadow: 5px 5px 5px -2px rgba(122, 122, 122, 0.75);
               }
          }
          }


        }


      }
      .message-input{
        padding: 10px;
        background-color: rgb(255, 255, 255);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px -5px 5px -2px rgba(122, 122, 122, 0.75);

        .messageActual {
          width: 100%;
          border: none;
          outline: none;
          color: rgb(0, 0, 0);
          font-size: 16px;
        }

        .send {
          display: flex;
          align-items: center;
        }
        
        .send button,
        .send .attachmentIcon {
       
          font-size: 25px;
          color: var(--wvu-blue);
          background-color: white;
          border: none;
          display: flex;
          align-items: center;
          margin-top: -3px; 
        }
        
        .send button:hover {
          color: var(--wvu-gold);
        }
        
        .send .attachmentIcon:hover {
          color: var(--wvu-gold);
        }
        
      }

   


    }

    

    

    .chatWindow .chatInfo .chatIcons {

      display: flex;
      gap: 15px;
    
     
     

     
      
    }
  }



}





