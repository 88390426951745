
.grid-container{
    background-color: #ffffff;
    margin-top: 120px;
    border: 1px solid white;
    border-radius: 10px;
    max-width:1000px; 
    color:white;
    display: flex;
}

.event-list {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(800px, 1fr)); */
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
    margin-top: 10px;
}
.event-header {
    color: #003776;
    text-align: center;
}
.event {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.event h2 {
    color: #333;
    margin-bottom: 6px;
}
    
    
.event p {
    text-align: left;
    margin: 10px 0;
    color: #666;
}
    
    
#RSVP-button{
    padding: 10px 50px;
    margin-right: 10px;
    font-size:medium;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.search-container input[type="text"] {
    padding: 8px;
    width: 200px;
}
.search-container button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 5px;
}
    